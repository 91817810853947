
<template>
    <div class="thematicLibrary-addPop">
        <div class="handle-btn">
            <el-button type="primary" size="small" @click="handleSave">
                保存
            </el-button>
        </div>
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="名称">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="默认图标">
                <el-input v-model="form.icon"></el-input>
            </el-form-item>
            <el-form-item label="悬停图标">
                <el-input v-model="form.iconHover"></el-input>
            </el-form-item>
            <el-form-item label="显示顺序">
                <el-input v-model="form.sortNum"></el-input>
            </el-form-item>
            <el-form-item label="描述">
                <el-input v-model="form.remarks"></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            form: {},
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 保存
        handleSave() {
            window.hideToast();
        },
    },
    created() {

    },
    mounted() {},
};
</script>
<style lang="stylus">
.thematicLibrary-addPop
    width 100%
    padding .2rem
    .handle-btn
        text-align right
        margin .1rem 0
</style>